var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
    l10ns: {}
  };
  var Japanese = {
    weekdays: {
      shorthand: ["\u65E5", "\u6708", "\u706B", "\u6C34", "\u6728", "\u91D1", "\u571F"],
      longhand: ["\u65E5\u66DC\u65E5", "\u6708\u66DC\u65E5", "\u706B\u66DC\u65E5", "\u6C34\u66DC\u65E5", "\u6728\u66DC\u65E5", "\u91D1\u66DC\u65E5", "\u571F\u66DC\u65E5"]
    },
    months: {
      shorthand: ["1\u6708", "2\u6708", "3\u6708", "4\u6708", "5\u6708", "6\u6708", "7\u6708", "8\u6708", "9\u6708", "10\u6708", "11\u6708", "12\u6708"],
      longhand: ["1\u6708", "2\u6708", "3\u6708", "4\u6708", "5\u6708", "6\u6708", "7\u6708", "8\u6708", "9\u6708", "10\u6708", "11\u6708", "12\u6708"]
    },
    time_24hr: true,
    rangeSeparator: " \u304B\u3089 ",
    monthAriaLabel: "\u6708",
    amPM: ["\u5348\u524D", "\u5348\u5F8C"],
    yearAriaLabel: "\u5E74",
    hourAriaLabel: "\u6642\u9593",
    minuteAriaLabel: "\u5206"
  };
  fp.l10ns.ja = Japanese;
  var ja = fp.l10ns;
  exports.Japanese = Japanese;
  exports.default = ja;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const Japanese = exports.Japanese,
      __esModule = exports.__esModule;